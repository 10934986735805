<template>
  <div>
    <b-card>
      <b-card-text>
        <b-row>
          <b-col cols="2">
            <label>选择数据表</label>
            <v-select
                v-model="filterValues.table"
                :options="table_filters"
                :placeholder="$t('No filter')"
                :clearable="false"
                :reduce="(text) => text.value"
                label="text"
            />
          </b-col>
          <b-col cols="2">
            <label>
              {{ $t("Choose a date") }}
            </label>
            <flat-pickr
                v-model="filterValues.time"
                :config="{
                                wrap: true,
                                time_24hr: true,
                                mode: 'range',
                                locale: $i18n.locale === 'cn' ? 'zh' : 'en',
                            }"
                class="form-control"
            />
          </b-col>
          <b-col cols="2">
            <label>设备名称<small>(模糊查询)</small></label>
            <b-form-input
                v-model="filterValues.eqname"
                trim
                placeholder="- 输入需要筛选的设备名称 -"
            />
          </b-col>
<!--          <b-col cols="2">-->
<!--            <label>能源类型</label>-->
<!--            <v-select-->
<!--                v-model="filterValues.energy_type"-->
<!--                :options="-->
<!--                                $store.state.jsf35.energy_filters.energy_type-->
<!--                            "-->
<!--                :placeholder="$t('No filter')"-->
<!--            />-->
<!--          </b-col>-->
          <b-col cols="2" v-if="filterValues.energy_type === '电'">
            <label>用电分项</label>
            <v-select
                v-model="filterValues.ec_type"
                :options="$store.state.jsf35.energy_filters.ec_type"
                multiple
                :placeholder="$t('No filter')"
            />
          </b-col>
          <b-col cols="2">
            <label>位置</label>
            <v-select
                v-model="filterValues.posiname"
                :options="
                                $store.state.jsf35.energy_filters.position
                            "
                multiple
                :placeholder="$t('No filter')"
            />
          </b-col>
          <b-col cols="2" class="text-right pt-2">
            <b-button variant="primary" @click="refetchData">查询</b-button>
          </b-col>
        </b-row>
      </b-card-text>
    </b-card>

    <!-- Table Container Card -->

    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
              class="
                            d-flex
                            align-items-center
                            justify-content-start
                            mb-1 mb-md-0
                        "
              cols="12"
              md="6"
          >
            <label style="margin: 0.5rem 0">{{ $t("Show") }}</label>
            <v-select
                v-model="perPage"
                :clearable="false"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                class="per-page-selector d-inline-block mx-50"
                size="sm"
            />
            <label style="margin: 0.5rem 0">{{
                $t("Entries")
              }}</label>
          </b-col>
        </b-row>
      </div>

      <b-table
          ref="refEnergyListTable"
          :busy="isBusy"
          :empty-text="$t('No Data')"
          :fields="tableColumns"
          :items="fetchEnergyData"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
          bordered
          class="text-center position-relative"
          hover
          primary-key="alarm_id"
          responsive
          show-empty
          small
          striped
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle mr-1"></b-spinner>
            <strong>{{ $t("Loading") }}...</strong>
          </div>
        </template>
        <!-- Column: Index -->
        <template #cell(index)="data">
          <div class="text-nowrap">
                        <span class="align-text-top text-capitalize">
                            {{ data.index + 1 }}
                        </span>
          </div>
        </template>
        <template #cell(time)="data">
                    <span>{{
                        data.item.time ? momentFmt(data.item.time) : "N/A"
                      }}</span>
        </template>
        <template #cell(posiname)="data">
                    <span>{{
                        data.item.posiname
                            ? data.item.posiname.replace("锦什坊街叁拾伍号", "")
                            : "N/A"
                      }}</span>
        </template>
        <!-- Column: Index -->
        <template #cell(actions)="data">
          <b-button
              v-b-modal:edit-energy-modal
              class="btn-icon"
              size="sm"
              variant="warning"
              @click="
                            energyDataItem = data.item;
                            energyDataItem.new_value = data.item.value;
                        "
          >
            <feather-icon icon="EditIcon"/>
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
              class="
                            d-flex
                            align-items-center
                            justify-content-center justify-content-sm-start
                        "
              cols="12"
              sm="6"
          >
                        <span class="text-muted">
                            {{
                            `
                                    ${$t("Show")}
                                    ${dataMeta.from}
                                    ${$t("to")}
                                    ${dataMeta.to}
                                    ${$t("of")}
                                    ${total}
                                    ${$t("Entries")}
                                    `
                          }}
                        </span>
          </b-col>
          <!-- Pagination -->
          <b-col
              class="
                            d-flex
                            align-items-center
                            justify-content-center justify-content-sm-end
                        "
              cols="12"
              sm="6"
          >
            <b-pagination
                v-model="currentPage"
                :per-page="perPage"
                :total-rows="total"
                hide-goto-end-buttons
                size="sm"
            ></b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal
        id="edit-energy-modal"
        title="能耗数据修改"
        ok-title="提交"
        cancel-title="取消"
        centered
        @ok="editEnergyData"
        @hidden="energyDataItem = null"
    >
      <div class="my-1">
        <b-row no-gutters v-if="energyDataItem">
          <b-col cols="5" class="text-right mb-50 pr-50">ID :</b-col>
          <b-col cols="7"> {{ energyDataItem.id }}</b-col>
          <b-col cols="5" class="text-right mb-50 pr-50"
          >设备名称 :
          </b-col
          >
          <b-col cols="7"> {{ energyDataItem.eqname }}</b-col>
          <b-col cols="5" class="text-right mb-50 pr-50"
          >能耗类型 :
          </b-col
          >
          <b-col cols="7">{{ filterValues.energy_type }}</b-col>
          <template v-if="filterValues.energy_type === '电'">
            <b-col cols="5" class="text-right mb-50 pr-50"
            >用电分项 :
            </b-col
            >
            <b-col cols="7">{{ energyDataItem.egname }}</b-col>
          </template>
          <b-col cols="5" class="text-right mb-50 pr-50"
          >位置 :
          </b-col
          >
          <b-col cols="7">{{ energyDataItem.posiname }}</b-col>
          <b-col cols="5" class="text-right mb-50 pr-50"
          >时间 :
          </b-col
          >
          <b-col cols="7">{{ energyDataItem.time }}</b-col>
          <b-col
              cols="5"
              class="text-right my-50 pr-50"
              style="line-height: 38px"
          >数值 :
          </b-col
          >
          <b-col cols="7" class="my-50 d-flex">
                        <span style="line-height: 38px">{{
                            energyDataItem.value
                          }}</span>
            <span class="px-1" style="line-height: 38px"
            ><feather-icon icon="ArrowRightIcon"
            /></span>
            <b-form-input
                style="width: 10rem"
                v-model="energyDataItem.new_value"
                number
                placeholder="新数值"
            ></b-form-input>
          </b-col>
          <b-col cols="5" class="text-right mb-50 pr-50"
          >单位 :
          </b-col
          >
          <b-col cols="7">{{ energyDataItem.unit }}</b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import vSelect from "vue-select";
import JsonExcel from "vue-json-excel";
import flatPickr from "vue-flatpickr-component";
import zh from "flatpickr/dist/l10n/zh.js";
import Ripple from "vue-ripple-directive";
import {computed, reactive, toRefs, watch} from "@vue/composition-api";
import {showToast} from "@/libs/utils/showToast";
import {getEnergyDataList, updateEnergyData} from "@/api/jsf35";
import PageTitle from "@/views/layout/PageTitle";
import {routerParams} from "@/libs/utils/routerParams";
import {t} from "@core/libs/i18n/utils";
import {momentFmt, momentJs} from "@/libs/utils/moment";

export default {
  directives: {
    Ripple,
  },
  components: {
    PageTitle,
    vSelect,
    flatPickr,
    downloadExcel: JsonExcel,
  },
  setup() {
    const event = reactive({
      project_id: computed(() => {
        return routerParams("project_id");
      }),
      filterOptions: {},
      filterValues: {
        table: "d",
        eqname: "",
        energy_type: "电",
        posiname: null,
        ec_type: null,
        time:
            momentJs().startOf("month").format() +
            " 至 " +
            momentJs().format(),
      },
      table_filters: [
        {text: "时表", value: "h"},
        {text: "日表", value: "d"},
        {text: "月表", value: "m"},
        {text: "年表", value: "y"},
      ],
      isEditEnergySidebarActive: false,
      refEnergyListTable: null,
      perPage: 20,
      searchQuery: "",
      tableColumns: [
        {
          label: "#",
          key: "index",
        },
        {
          label: "设备名称",
          key: "eqname",
          sortable: true,
        },
        {
          label: "能耗类型",
          key: "egname",
          sortable: true,
        },
        {
          label: "数值",
          key: "value",
        },
        {
          label: "单位",
          key: "unit",
          sortable: true,
        },
        {
          label: "位置",
          key: "posiname",
          sortable: true,
        },
        {
          label: "时间",
          key: "time",
          sortable: true,
        },
        {
          label: "操作",
          key: "actions",
        },
      ],
      total: 0,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      sortBy: "",
      isSortDirDesc: false,
      dataMeta: computed(() => {
        const localItemsCount = event.refEnergyListTable
            ? event.refEnergyListTable.localItems.length
            : 0;
        return {
          from:
              event.perPage * (event.currentPage - 1) +
              (localItemsCount ? 1 : 0),
          to:
              event.perPage * (event.currentPage - 1) +
              localItemsCount,
          of: event.total,
        };
      }),
      isBusy: false,
      energyDataItem: null,
    });

    watch([() => event.currentPage, () => event.perPage], () => {
      refetchData();
    });

    // watch(
    //     event.filterValues,
    //     () => {
    //       refetchData();
    //     },
    //     "deep"
    // );

    const refetchData = () => {
      event.refEnergyListTable.refresh();
    };

    const fetchEnergyData = (ctx, callback) => {
      event.isBusy = true;
      const filters = getFilters();
      let params = {
        project_id: event.project_id,
        ...filters,
      };

      params = {
        ...params,
        sortBy: event.sortBy,
        sortDesc: event.isSortDirDesc ? 1 : 0,
        pagesize: event.perPage,
        page: event.currentPage,
      };

      getList(params)
          .then((response) => {
            const {list, total} = response.data;
            callback(list);
            event.total = total;
            event.isBusy = false;
          })
          .catch((error) => {
            event.isBusy = false;
            showToast(error);
          });
    };

    /**
     * 获取列表数据
     */
    const getList = async (params) => {
      return getEnergyDataList({
        project_id: event.project_id,
        ...params,
      });
    };

    /**
     * 处理导出数据的头部
     * @param fields
     */
    const exportExcelHeaders = (fields) => {
      let headers = {};
      fields.map((item) => {
        if (item.key !== "index" && item.key !== "actions") {
          let header = t(item.label) + "(" + item.key + ")";
          headers[header] = item.key;
        }
      });
      return headers;
    };
    /**
     * 获取筛选条件
     */
    const getFilters = () => {
      let filters = {};
      for (const Key in event.filterValues) {
        let Value = event.filterValues[Key];
        if (!!Value) {
          filters[Key] = Value;
          if (Key === "time") {
            Value = Value.replaceAll(" 至 ", ",").replaceAll(
                " to ",
                ","
            );
            const timeArr = Value.split(",");
            const start = momentJs(timeArr[0])
                .startOf("day")
                .format("YYYY-MM-DD HH:mm:ss");
            const end = momentJs(timeArr[1] || timeArr[0])
                .endOf("day")
                .format("YYYY-MM-DD HH:mm:ss");
            filters[Key] = "(" + start + "," + end + ")";
          }
          if (Key === "eqname") {
            filters[Key] = '%' + Value
          }
        }
      }
      return filters;
    };
    /**
     * 下载数据
     * @returns {Promise<*>}
     */
    const downloadData = async () => {
      const filters = getFilters();
      const params = {
        sortBy: event.sortBy,
        sortDesc: event.isSortDirDesc ? 1 : 0,
        ...filters,
      };
      const res = await getList(params);
      return res.data.list;
    };

    //修改能耗数据
    const editEnergyData = () => {
      const params = {
        project_id: event.project_id,
        table: event.filterValues.table,
        id: event.energyDataItem.id,
        value: event.energyDataItem.new_value,
      };
      updateEnergyData(params)
          .then(() => {
            showToast(null, "成功", "数据修改成功");
            refetchData();
          })
          .catch((error) => {
            showToast(error);
          });
    };

    return {
      ...toRefs(event),
      fetchEnergyData,
      exportExcelHeaders,
      downloadData,
      zh,
      momentFmt,
      momentJs,
      editEnergyData,
      refetchData
    };
  },
};
</script>

<style lang="scss">
/* .per-page-selector {
width: 90px;
}

.b-form-btn-label-control.dropdown.b-form-datepicker.form-control
label.form-control {
padding: 0.3rem 1rem;
} */
</style>

<style lang="scss">
//noinspection CssUnknownTarget
@import "@/@core/scss/vue/libs/vue-flatpicker";
</style>
